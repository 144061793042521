<template>
  <v-app>
    <Navbar :drawerState="drawer" @triggerDrawer="drawer = !drawer" />
    <router-view />
    <!-- Mobile Drawer (für Burger-Icon) -->
    <NavigationDrawer @updateDrawer="drawer = !drawer" :drawer="drawer" />
    <Footer @scrollTop="scrollTop" />
  </v-app>
</template>

<script>
import Navbar from "@/components/layout/Navbar";
import NavigationDrawer from "@/components/layout/NavigationDrawer";
import Footer from "@/components/layout/Footer";

export default {
  name: "App",
  components: {
    Navbar,
    NavigationDrawer,
    Footer,
  },
  data() {
    return {
      drawer: false,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.drawer = false; // Schließt den Drawer bei jedem Router-Wechsel
      next(); // Erlaubt den Navigationswechsel
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.responsive-text {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* Webkit-basierten Browsern (Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Verstecke die Scrollbar */
}

/* Firefox */
* {
  scrollbar-width: none; /* Verstecke die Scrollbar in Firefox */
  scrollbar-color: transparent transparent; /* Verstecke die Scrollbar-Farben */
}

/* Optionale zusätzliche Regel für alle anderen Browser */
html,
body {
  -ms-overflow-style: none; /* Verstecke die Scrollbar in Internet Explorer und Edge */
  overflow: -moz-scrollbars-none; /* Verstecke die Scrollbar in älteren Versionen von Firefox */
}
</style>
