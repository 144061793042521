<template>
  <div class="header-content pt-10">
    <v-img
      :src="logo"
      max-height="300px"
      height="200"
      contain
      class="header-image contain mt-8"
    ></v-img>
    <v-img class="header-logo"></v-img>
  </div>
</template>
<script>
import logo from "@/assets/logo-small.webp";
export default {
  data() {
    return {
      logo,
    };
  },
};
</script>

<style>
.header-content {
  position: relative;
  text-align: center;
  color: white;

  background-color: #f9f4ed;
}

.header-image {
  width: 100%;
  height: auto;
}
</style>
