<template>
  <v-container
    fluid
    style="background-color: #f9f4ed; border-bottom: 4px solid #f6eadf"
    class="py-8"
  >
    <v-card-title
      style="color: #224550"
      class="text-h4 font-weight-black header-title"
      >white pearl Ink</v-card-title
    >
    <v-card-subtitle class="text-h5">Tattoos & Kunstwerke</v-card-subtitle>
    <v-card-text class="responsive-text"
      >Herzlich willkommen bei White Pearl Ink! Ich bin Rebecca, Künstlerin mit
      Leidenschaft für Tattoos und Malerei. Ob individuelles Tattoo oder
      einzigartiges Gemälde – ich freue mich darauf, deine Ideen gemeinsam mit
      dir zu verwirklichen.
    </v-card-text>

    <v-btn
      @click="$router.push('/tattoos#terminvergabe')"
      class="ml-auto mr-5"
      color="transparent"
      variant="flat"
      ><v-icon class="pr-2">mdi-arrow-right</v-icon>Terminvergabe</v-btn
    >
  </v-container>
</template>
<script></script>
<style>
.header-title {
}
</style>
