<template>
  <div class="home">
    <Header />
    <FirstSection />

    <AboutSection />
    <TattooSection />
    <ArtSection />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Header from "@/components/home/Header.vue";
import FirstSection from "@/components/home/FirstSection.vue";
import AboutSection from "@/components/home/AboutSection.vue";
import TattooSection from "@/components/home/TattooSection.vue";
import ArtSection from "@/components/home/ArtSection.vue";
import ParallaxSection from "@/components/home/ParallaxSection.vue";

export default {
  name: "HomeView",
  beforeRouteEnter(to, from, next) {
    document.title = "Kunstwerke & Tattoos in Kreuzlingen | White Pearl Ink";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Entdecke individuelle Tattoos und einzigartige Gemälde von Künstlerin Rebecca Sophia und lasse deine kreativen Ideen im Tattoostudio Kreuzlingen verwirklichen."
      );
    }
    next();
  },
  components: {
    Header,
    FirstSection,
    AboutSection,
    ArtSection,
    TattooSection,
    ParallaxSection,
  },
};
</script>
