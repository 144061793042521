<template>
  <v-container fluid class="py-8" style="background-color: #f9f4ed">
    <v-card-title class="text-h5">Tattoos</v-card-title>
    <v-card-text class="responsive-text"
      >Tattoos begleiten ihre Träger ein Leben lang und sind Ausdruck von
      Individualität, mit einer ganz eigenen Geschichte dahinter. Hier findest
      du alle wichtigen Informationen zur Terminvergabe, sowie zur Vor- und
      Nachsorge deines Tattoos.
    </v-card-text>
    <v-btn
      @click="$router.push('/tattoos')"
      class="ml-auto mr-5"
      color="transparent"
      variant="flat"
      ><v-icon class="pr-2">mdi-arrow-right</v-icon>Tattoos</v-btn
    >
    <GridGalleryTattoos />
  </v-container>
</template>
<script>
import GridGalleryTattoos from "@/components/home/GridGalleryTattoos.vue";

export default {
  components: { GridGalleryTattoos },
  data() {
    return {};
  },
};
</script>
<style></style>
