<template>
  <v-app-bar
    :class="[
      { 'transparent-navbar': isTop && isHomepage && !drawerState },
      { 'no-elevation': isTop && isHomepage && !drawerState },
    ]"
    app
    color="#90a9b0"
    style="z-index: 10000"
  >
    <!-- Linker Teil mit Burger-Icon -->
    <div style="width: 110px" class="d-flex justify-start">
      <v-app-bar-nav-icon
        :color="isTop && isHomepage && !drawerState ? 'secondary' : 'white'"
        @click.stop="$emit('triggerDrawer')"
      ></v-app-bar-nav-icon>
    </div>
    <v-spacer></v-spacer>
    <!-- Platzhalter für das Logo -->
    <div
      v-if="(!isTop && isHomepage) || !isHomepage || drawerState"
      class="flex-grow-1 d-flex justify-center"
    >
      <v-img
        @click="$router.push('/')"
        :src="isTop && isHomepage && !drawerState ? logo : logoWhite"
        height="50"
        contain
      ></v-img>
    </div>
    <v-spacer></v-spacer>
    <!-- Rechter Teil mit "Join Us"-Button -->
    <div style="width: 110px">
      <v-img
        class="mr-5"
        position="right"
        height="40"
        :src="isTop && isHomepage && !drawerState ? instagramBasic : instagram"
        @click="openInstagram"
      ></v-img>
    </div>
  </v-app-bar>
</template>

<script>
import { ref } from "vue";
import logo from "@/assets/logo-small.webp";
import logoWhite from "@/assets/logo_white-small.webp";
import instagramBasic from "@/assets/instagram_basic-small.webp";
import instagram from "@/assets/instagram-small.webp";

export default {
  name: "Navbar",
  props: {
    drawerState: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      logo,
      logoWhite,
      instagram,
      instagramBasic,
      isTop: true,
      isHomepage: false,
    };
  },
  mounted() {
    this.checkScrollPosition();
    window.addEventListener("scroll", this.checkScrollPosition);
    this.checkIfHomepage();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkScrollPosition);
  },

  methods: {
    checkScrollPosition() {
      this.isTop = window.scrollY === 0;
    },
    checkIfHomepage() {
      this.isHomepage = this.$route.path === "/";
    },
    openInstagram() {
      window.open("https://www.instagram.com/whitepearl.ink", "__blank");
    },
  },
  watch: {
    "$route.path": "checkIfHomepage",
  },
};
</script>

<style scoped>
.transparent-navbar {
  background-color: transparent !important;
}
.v-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.custom-white-text {
  color: white !important;
}

.no-elevation {
  box-shadow: none !important;
}
</style>
