import { createApp } from "vue";
// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import "@mdi/font/css/materialdesignicons.css";
import App from "./App.vue";
import router from "./router";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", // Setze das Standard-Icon-Set auf 'mdi'
  },
  theme: {
    themes: {
      light: {
        colors: {
          darkbackground: "#343541",
          //primary: '#00963f',
          primary: "#f1e7d7",
          secondary: "#2F4858",
        },
      },
    },
  },
});

const app = createApp(App);

app.use(router);

app.use(vuetify);

app.mount("#app");
