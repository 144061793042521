<template>
  <v-parallax height="600px" :src="image"></v-parallax>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
