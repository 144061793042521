<template>
  <div>
    <v-row
      :style="
        $vuetify.display.mdAndUp
          ? ''
          : 'border-bottom: 4px solid rgb(246, 234, 223)'
      "
      no-gutters
    >
      <v-col class="d-flex justify-center" cols="12">
        <v-img
          :class="{ 'mt-7 pt-7': $vuetify.display.mdAndUp }"
          :max-width="$vuetify.display.mdAndUp ? '300' : ''"
          :src="art1"
        ></v-img>
      </v-col>
    </v-row>
    <v-container fluid class="py-8">
      <v-card-title class="text-h5">Über mich</v-card-title>
      <v-card-text class="responsive-text"
        >Schon früh entdeckte ich meine Leidenschaft für die Kunst und heute
        verwirkliche ich diesen Traum als Tätowiererin und Künstlerin.
        Inspiriert von Natur und Fantasie, bringe ich meine Kreativität sowohl
        auf Haut als auch auf Leinwand.
      </v-card-text>

      <v-btn
        @click="$router.push('about-me')"
        class="ml-auto mr-5"
        color="transparent"
        variant="flat"
        ><v-icon class="pr-2">mdi-arrow-right</v-icon>Über mich</v-btn
      >
    </v-container>
  </div>
</template>
<script>
import ParallaxSection from "./ParallaxSection.vue";
import art1 from "@/assets/art/art_1-large.webp";
export default {
  components: { ParallaxSection },
  data() {
    return {
      art1,
    };
  },
};
</script>
<style></style>
