<template>
  <v-navigation-drawer
    style="z-index: 10000"
    :model-value="drawer"
    @update:modelValue="$emit('updateDrawer', $event)"
  >
    <v-list-item
      @click="$router.push('/')"
      class="mb-1"
      title="white pearl Ink"
      subtitle="by Rebecca Sophia"
    ></v-list-item>
    <v-divider></v-divider>
    <v-list-item
      @click="$router.push('/about-me')"
      link
      title="Über mich"
    ></v-list-item>
    <v-list-item
      @click="$router.push('/tattoos')"
      link
      title="Tattoos"
    ></v-list-item>

    <v-list-item
      @click="$router.push('/art')"
      link
      title="Kunstwerke"
    ></v-list-item>
    <v-list-item
      @click="$router.push('/pets')"
      link
      title="Haustiere verewigen"
    ></v-list-item>
    <v-list-item
      @click="$router.push('/studio')"
      link
      title="Studio"
    ></v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",
  props: ["drawer"],
};
</script>

<style>
.v-list-item-title {
  white-space: break-spaces;
}
</style>
