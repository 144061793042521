<template>
  <v-container class="py-8">
    <v-card-title class="text-h5">Kunstwerke</v-card-title>
    <v-card-text class="responsive-text"
      >Hier findest du alle Informationen zu meinen Kunstwerken, sei es als
      Kunstprodukt oder individuelle Auftragsarbeit. Entdecke mein
      Kunstportfolio, das von der Schönheit der Natur und mystischen
      Fantasiewelten inspiriert ist.
    </v-card-text>
    <v-btn
      @click="$router.push('/art')"
      class="ml-auto mr-5"
      color="transparent"
      variant="flat"
      ><v-icon class="pr-2">mdi-arrow-right</v-icon>Kunstwerke</v-btn
    >
    <v-carousel
      class="mt-0"
      cycle
      interval="10000"
      :show-arrows="false"
      delimiter-icon="mdi-square"
      hide-delimiter-background
    >
      <v-carousel-item :src="art5"> </v-carousel-item>
      <v-carousel-item :src="art2"> </v-carousel-item>
      <v-carousel-item :src="art3"> </v-carousel-item>
      <v-carousel-item :src="art4"> </v-carousel-item>
    </v-carousel>
  </v-container>
</template>
<script>
import art2 from "@/assets/art/art_2-large.webp";
import art3 from "@/assets/art/art_3-large.webp";
import art4 from "@/assets/art/art_4-large.webp";
import art5 from "@/assets/art/art_5-large.webp";

export default {
  data() {
    return {
      art2,
      art3,
      art4,
      art5,
    };
  },
};
</script>
<style scoped>
::v-deep .v-carousel__controls__item {
  color: #90a9b0;
}
</style>
