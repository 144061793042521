<template>
  <v-container class="mt-5">
    <v-row>
      <template v-for="(image, imgIdx) in imageLayout" :key="imgIdx">
        <v-col class="d-flex justify-center" cols="6" md="3">
          <v-img
            max-width="300"
            :src="image.src"
            contain
            @click="openDialog(imgIdx)"
          ></v-img>
        </v-col>
      </template>
    </v-row>

    <v-dialog v-model="dialog" max-width="800px">
      <v-img :src="dialogImage" max-height="600px" contain></v-img>
    </v-dialog>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import tattoo1 from "@/assets/tattoos/tattoo_1-large.webp";
import tattoo2 from "@/assets/tattoos/tattoo_2-large.webp";
import tattoo3 from "@/assets/tattoos/tattoo_3-large.webp";
import tattoo4 from "@/assets/tattoos/tattoo_4-large.webp";

const imageLayout = [
  { cols: 6, src: tattoo1 },
  { cols: 6, src: tattoo2 },
  { cols: 6, src: tattoo3 },
  { cols: 6, src: tattoo4 },
];

const dialog = ref(false);
const dialogImage = ref("");

const openDialog = (imgIdx) => {
  dialogImage.value = imageLayout[imgIdx];
  dialog.value = true;
};
</script>
