import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/about-me",
    name: "about",

    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/pets",
    name: "pets",

    component: () => import("../views/PetsView.vue"),
  },
  {
    path: "/art",
    name: "art",

    component: () => import("../views/ArtView.vue"),
  },
  {
    path: "/tattoos",
    name: "tattoo",

    component: () => import("../views/TattooView.vue"),
  },
  {
    path: "/studio",
    name: "studio",

    component: () => import("../views/StudioView.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",

    component: () => import("../views/Impressum.vue"),
  },
  {
    path: "/datenschutz",
    name: "datenschutz",

    component: () => import("../views/Datenschutz.vue"),
  },
  {
    path: "/:pathMatch(.*)*", // catch-all Route
    name: "notFound",
    redirect: "/", // Redirect to home
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Wenn savedPosition existiert (z.B. bei Vor-/Zurück-Navigation), nutze diese
    if (savedPosition) {
      return savedPosition;
    } else {
      // Scrolle immer zur obersten Position
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = ["impressum", "datenschutz", "agb"]; // Route-Namen der Seiten, die nicht indexiert werden sollen

  if (publicPages.includes(to.name)) {
    // Wenn die Seite in der Liste der publicPages ist, setze noindex, nofollow
    document.title = `${
      to.name.charAt(0).toUpperCase() + to.name.slice(1)
    } - white pearl ink`;
    const metaRobots = document.querySelector('meta[name="robots"]');
    if (metaRobots) {
      metaRobots.setAttribute("content", "noindex, nofollow");
    } else {
      const meta = document.createElement("meta");
      meta.name = "robots";
      meta.content = "noindex, nofollow";
      document.head.appendChild(meta);
    }

    const googlebot = document.querySelector('meta[name="googlebot"]');
    if (googlebot) {
      googlebot.setAttribute("content", "noindex, nofollow");
    } else {
      const meta = document.createElement("meta");
      meta.name = "googlebot";
      meta.content = "noindex, nofollow";
      document.head.appendChild(meta);
    }
  } else {
    // Für alle anderen Seiten stelle sicher, dass der Meta-Tag auf index, follow gesetzt ist oder entfernt wird
    const metaRobots = document.querySelector('meta[name="robots"]');
    if (metaRobots) {
      metaRobots.setAttribute("content", "index, follow");
    }
    const googlebot = document.querySelector('meta[name="googlebot"]');
    if (googlebot) {
      googlebot.setAttribute("content", "index, follow");
    }
  }

  next();
});

export default router;
