<template>
  <v-container fluid class="footer pt-0 d-flex flex-column">
    <v-btn
      @click="scrollToTop"
      style="
        font-size: 13px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      "
      variant="tonal"
      height="60px"
      class="mx-auto text-white"
    >
      <v-icon>mdi-arrow-up</v-icon>
      nach Oben
    </v-btn>

    <div class="d-flex flex-column text-center py-5 text-white">
      <v-list-item
        @click="openLink('/about-me')"
        class="upper-section"
        link
        title="Über mich"
      ></v-list-item>
      <v-list-item
        @click="openLink('/tattoos')"
        class="upper-section"
        link
        title="Tattoos"
      ></v-list-item>

      <v-list-item
        @click="openLink('/art')"
        class="upper-section"
        link
        title="Kunstwerke"
      ></v-list-item>
      <v-list-item
        @click="openLink('/pets')"
        class="upper-section"
        link
        title="Haustiere verewigen"
      ></v-list-item>
      <v-list-item
        @click="openLink('/studio')"
        class="upper-section"
        link
        title="Studio"
      ></v-list-item>
    </div>
    <v-divider color="white"></v-divider>
    <div class="d-flex flex-column text-center py-5 text-white">
      <v-list-item
        @click="$router.push('/impressum')"
        class="lower-section"
        link
        title="Impressum"
      ></v-list-item>
      <v-list-item
        @click="$router.push('/datenschutz')"
        class="lower-section"
        link
        title="Datenschutz"
      ></v-list-item>
    </div>
    <div style="max-width: 400px" class="d-flex mx-auto">
      <v-row>
        <v-col cols="6">
          <v-img
            class="cursor-pointer"
            width="50px"
            @click="
              openLinkNewWindow('https://www.instagram.com/whitepearl.ink')
            "
            :src="instagram"
          ></v-img>
        </v-col>
        <v-col @click="sendMail" cols="6">
          <v-icon
            class="cursor-pointer text-white"
            style="font-size: 64px; height: 51px"
            >mdi-email-outline</v-icon
          >
        </v-col>
        <v-col v-if="false" cols="6">
          <v-img
            class="cursor-pointer"
            width="50px"
            @click="openLinkNewWindow('https://www.tiktok.com')"
            :src="tiktok"
          ></v-img>
        </v-col>
      </v-row>
    </div>
    <v-card-text class="mt-3 text-center text-white"
      >© {{ currentYear }} All rights reserved.</v-card-text
    >
  </v-container>
</template>
<script>
import instagram from "@/assets/instagram-small.webp";
import tiktok from "@/assets/tiktok-small.webp";
export default {
  data() {
    return {
      instagram,
      tiktok,
    };
  },
  methods: {
    sendMail() {
      window.open("mailto:rebecca.sophiaa.p@gmail.com?subject=Tattooanfrage");
    },
    scrollToTop() {
      this.$emit("scrollTop");
    },
    openLink(link) {
      this.$router.push(link);
      this.scrollToTop();
    },
    openLinkNewWindow(link) {
      window.open(link, "_blank");
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
.footer {
  background-color: #90a9b0;
}
.upper-section .v-list-item-title {
  font-size: 20px;
}
.v-list-item__overlay {
  opacity: 0 !important;
}
.lower-section .v-list-item-title {
  font-size: 14px;
}
</style>
